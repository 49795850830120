<template>
    <div>
        <div class="fix-top">
            <van-tabs @click="onClickTab">
                <van-tab title="未使用"></van-tab>
                <van-tab title="已使用"></van-tab>
                <van-tab title="已过期"></van-tab>
            </van-tabs>
        </div>
        <div style="height: 3rem;"></div>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <coupon-item v-for="(item, index) in listLoadingList" :key="index"
                         :status="status"
                         :money="item.money"
                         :full_money="item.full_money"
                         :title="item.title"
                         :valid_end_time="item.valid_end_time"
                         :couponId="item.coupon"
            ></coupon-item>
        </van-list>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import CouponItem from '../../components/order/CouponItem'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import FloatNav from '../../components/common/FloatNav'
  export default {
    name: 'CouponList',
    mixins: [globalConfig, listLoading],
    components: { FloatNav, CouponItem },
    data () {
      return {
        status: 0
      }
    },
    methods: {
      onClickTab: function (name, title) {
        switch (title) {
          case '未使用':
            this.status = 0
            break
          case '已使用':
            this.status = 2
            break
          case '已过期':
            this.status = 3
            break
          default:
            break
        }
        this.listLoadingInit(this.apiUrl + 'mall/coupon/getCouponList', 'couponList', 1, {
          status: this.status
        })
      }
    },
    created () {
      this.onClickTab('', '未使用')
    }
  }
</script>

<style scoped>
    .fix-top{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
</style>
